<template>
  <div class="mt-3 mb-5">

    <!-- Title -->
    <h1>
      {{ $t('posts.socialMedia') }}
    </h1>

    <!-- Twitter -->
    <a
      class="btn btn-primary btn-icon-only rounded-circle"
      href="https://twitter.com/Limmat_Group"
      target="_blank"
      rel="noopener"
    >
      <i class="fa fa-twitter text-black" />
    </a>

    <!-- Linkedin -->
    <a
      class="btn btn-primary btn-icon-only rounded-circle"
      href="https://www.linkedin.com/company/limmat-group"
      target="_blank"
      rel="noopener"
    >
      <i class="fa fa-linkedin text-black" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialMedia',
}
</script>

<style scoped>

  h1 {
    color: black !important;
    font-size: 18px !important;
  }

  .btn:active {
    background-color: red;
  }

</style>
