<template>
  <div class="list-img-wrap">

    <!-- Title -->
    <h1>
      {{ $t('posts.lastPosts') }}
    </h1>

    <template v-for="item in data">
      <div :key="item.text">
        <div v-if="item.id <= 3">
          <b-row class="my-3">

            <!-- Post Image -->
            <b-col
              lg="4"
              md="2"
            >
              <a href="home">
                <b-link :to="{ name: 'onePost', params: { idPost: item.id } }">
                  <b-img-lazy
                    :src="item.image"
                    class="img-fluid rounded-circle shadow-lg"
                    alt="Post Image"
                    style="height: 100px; width: 100px; object-fit: cover"
                  />
                </b-link>
              </a>
            </b-col>

            <!-- Title and desc -->
            <b-col
              lg="8"
              md="10"
              class="d-flex align-items-center"
            >

              <b-link :to="{ name: 'onePost', params: { idPost: item.id } }">
                {{ item.title }}
              </b-link>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MyData from 'Data/posts.json'

import moment from 'moment'
import {
  BRow,
  BCol,
  BImgLazy,
  BLink,
} from 'bootstrap-vue'

export default {
  name: 'LastPosts',
  components: {
    BRow,
    BCol,
    BImgLazy,
    BLink,
  },
  data() {
    return {
      data: MyData.data.posts,
    }
  },
  methods: {
    formatDate(date) {
      moment.locale('es')
      const postDate = moment(date, 'YYYY-MM-DD').format('LL')
      return postDate
    },
  },
}
</script>

<style scoped>

  p {
    color: #ff8f00 !important;
  }

  h1 {
    color: black !important;
    font-size: 18px !important;
  }

  a {
    color: black;
  }

</style>
